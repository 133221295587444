import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/Shared/Hero'
import Blog from '../components/Shared/Blog'
import { getUniqData, stripeHtmlTag } from '../utils/mixins'
import useEllipsis from '../hooks/useEllipsis'
import * as styles from './styles/blog-page.module.scss'
import Push from '../components/Shared/Push'
import Text from '../components/Core/Text'
import SelectBox, { SelectBoxOption } from '../components/Core/SelectBox'
import Paginate from 'react-paginate'
import useBreakpoint from '../hooks/useBreakpoint'
import DecoBigIllus from '../components/Illus/DecoBigIllus'
import { useTranslation } from '../hooks/useTranslation'
import replaceHife from '../utils/replaceHife'
import { Helmet } from 'react-helmet'
import Breadcrumb from '../components/Core/Breadcrumb'
import { useLocation } from '@reach/router'
import { head } from '../components/head'
import usePageView from '../hooks/usePageView'
import BlockSeoText from '../components/Shared/BlockSeoText'
import MultiSelectBox from '../components/Core/MultiSelectBox'

type FilterKey = 'city' | 'category'

export const Head = head('umbracoBlogPage')

export default function BlogIndex(props: { data: any; pageContext: any }) {
  const { t } = useTranslation('blog')
  const blogPage = props.data.umbracoBlogPage
  const posts = props.data.allUmbracoBlogPost.nodes
  const allPosts = props.data.allUmbracoBlogPostCount.nodes
  const { currentPage } = props.pageContext
  const pageTitle = blogPage?.metaTitle
    ? blogPage?.metaTitle
    : blogPage?.title
    ? blogPage?.title.replace(/\n/g, '')
    : 'Blog'

  const AllFilter = {
    ALL_CITIES: String(t('Toutes les villes', 'blog')),
    ALL_CATEGORIES: String(t('Toutes les catégories', 'blog')),
  }

  const ellipsis = useEllipsis()

  const breakpoint = useBreakpoint()
  const location = useLocation()

  const mapBlogData = (posts: any) => {
    const firstContent = posts.filter((_: any, index: number) => index < 2)
    const secondContent = posts.filter(
      (_: any, index: number) => index >= 2 && index < 6
    )
    const thirdContent = posts.filter(
      (_: any, index: number) => index >= 6 && index < 8
    )
    const lastContent = posts.filter((_: any, index: number) => index >= 8)

    return [firstContent, secondContent, thirdContent, lastContent]
  }

  const [blogData, setBlogData] = useState<any[]>(mapBlogData(posts))
  const [filter, setFilter] = useState<Record<string, any[]>>({})

  const mapAndSetBlogData = (posts: any) => {
    const mappedPosts = mapBlogData(posts)

    setBlogData(mappedPosts)
  }

  useEffect(() => {
    mapAndSetBlogData(posts)
  }, [])

  const filterPostByKey = (key: FilterKey, posts: any) => {
    if (filter[key] && filter[key].length > 0) {
      return posts.filter((p: any) =>
        filter[key]?.find((o: any) =>
          p[key]
            ? p[key][0]
              ? p[key][0].title === o.value
              : p[key].title === o.value
            : false
        )
      )
    }
    return posts
  }

  const onFilterData = (
    e: SelectBoxOption[],
    mainKey: FilterKey,
    otherKey: FilterKey,
    type: String
  ) => {
    setFilter((filter: any) => {
      const newFilter = {
        ...filter,
        [mainKey]: e,
      }

      console.log('Set filter', filter, e)
      if (newFilter[mainKey] && newFilter[mainKey].length) {
      }
      if (
        newFilter?.category?.find(
          (o: any) => o.value === AllFilter.ALL_CATEGORIES
        ) &&
        newFilter?.city?.find((o: any) => o.value === AllFilter.ALL_CITIES)
      ) {
        mapAndSetBlogData(allPosts)

        return {}
      } else {
        if (newFilter[mainKey].find((o: any) => o.value === type)) {
          mapAndSetBlogData(filterPostByKey(otherKey, allPosts))
        } else {
          if (!newFilter[mainKey].length) {
            mapAndSetBlogData(filterPostByKey(otherKey, allPosts))
          } else {
            const currentPosts = allPosts.filter((p: any) => {
              const found = newFilter[mainKey].find((o: any) =>
                p[mainKey]
                  ? p[mainKey][0]
                    ? p[mainKey][0].title === o.value
                    : p[mainKey].title === o.value
                  : false
              )
              return !!found
            })

            mapAndSetBlogData(filterPostByKey(otherKey, currentPosts))
          }
        }
      }

      return newFilter
    })
  }

  const handleFilterCity = (e: SelectBoxOption[]) => {
    onFilterData(e, 'city', 'category', AllFilter.ALL_CITIES)
  }

  const handleFilterCategory = (e: SelectBoxOption[]) => {
    onFilterData(e, 'category', 'city', AllFilter.ALL_CATEGORIES)
  }

  const handlePageChange = ({ selected }: { selected: number }) => {
    if (selected === 0) {
      navigate(`${blogPage?._url}`)
    } else {
      navigate(`${blogPage?._url}page/${selected + 1}/`)
    }
  }

  useEffect(() => {
    const { category } = window as any
    if (category) {
      handleFilterCategory({
        label: category,
        value: category,
      })
    }
    window.scrollTo(0, 0)

    if (typeof window !== 'undefined' && window.document) {
      usePageView(pageTitle, window.location.pathname)
    }
  }, [])

  return (
    <>
      <Helmet>
        <body className="blog-page" />
      </Helmet>
      <Layout>
        {/* <h1 className="hidden">{t('Le blog', 'blog')}</h1> */}
        <Hero
          title={blogPage?.title}
          image={blogPage?.imageCover}
          image-mobile={blogPage?.imageCoverMobile}
          video={blogPage?.videoCover}
          overlay={blogPage?.title && blogPage?.title !== ''}
          isBottom
        >
          <DecoBigIllus className="deco" />
        </Hero>
        <section className={styles.filter}>
          <div className="container">
            <MultiSelectBox
              label={
                ['sm', 'md'].includes(breakpoint)
                  ? t('Les villes', 'blog')
                  : AllFilter.ALL_CITIES
              }
              dropDownClassName={styles.filterDropdown}
              options={[
                // {
                //   label: ['sm', 'md'].includes(breakpoint)
                //     ? t('Les villes', 'blog')
                //     : AllFilter.ALL_CITIES,
                //   value: AllFilter.ALL_CITIES,
                // },
                ...getUniqData(allPosts, 'city'),
              ]}
              onChange={handleFilterCity}
              identifier="filterCity"
            />
            <MultiSelectBox
              label={
                ['sm', 'md'].includes(breakpoint)
                  ? t('Les catégories', 'blog')
                  : AllFilter.ALL_CATEGORIES
              }
              dropDownClassName={styles.filterDropdown}
              options={[
                // {
                //   label: ['sm', 'md'].includes(breakpoint)
                //     ? t('Les catégories', 'blog')
                //     : AllFilter.ALL_CATEGORIES,
                //   value: AllFilter.ALL_CATEGORIES,
                //   selected: false,
                // },
                ...getUniqData(allPosts, 'category', true),
              ]}
              onChange={handleFilterCategory}
              identifier="filterCateg"
            />
          </div>
        </section>
        {blogData.map((d, index) =>
          d && d.length ? (
            <section
              className={`${styles.blogSection} ${
                (index + 1) % 2 === 0 ? styles.small : styles.big
              } ${index === blogData.length - 1 ? styles.last : ''}`}
              key={`blog-${index}`}
            >
              <div className="container">
                <Blog
                  data={d.map((s: any) => ({
                    id: s.id,
                    title: s.title,
                    shortTitle: s.shortTitle,
                    category: s.category[0] ? s.category[0].title : '',
                    color: s.category[0] ? s.category[0].color : '',
                    content: ellipsis(
                      s.introduction || stripeHtmlTag(s.articleBody),
                      (index + 1) % 2 === 0 ? 80 : 205
                    ),
                    name: s.name,
                    thumbnail: s.thumbnail,
                    _url: s._url,
                    _urls: s._urls,
                  }))}
                  variant={(index + 1) % 2 === 0 ? 'sm' : 'lg'}
                  tag="div"
                />
              </div>
            </section>
          ) : (
            <></>
          )
        )}
        {allPosts.length > 12 && (
          <section className={styles.pagination}>
            <div className="container">
              <Paginate
                pageCount={Math.ceil(allPosts.length / 12)}
                className="pagination"
                nextRel={null}
                prevRel={null}
                forcePage={currentPage - 1}
                onPageChange={handlePageChange}
                hrefBuilder={(page) => {
                  if (page === 1) {
                    return `${blogPage?._url}`
                  } else {
                    return `${blogPage?._url}page/${page}/`
                  }
                }}
              />
            </div>
          </section>
        )}

        <Push
          data={{ itemsPush: blogPage?.blockPush }}
          className={styles.push}
        />
        {blogPage && blogPage.textSeo && currentPage < 2 && (
          <BlockSeoText content={blogPage.textSeo} />
          // <section className={`${styles.textSeo} seo-text`}>
          //   <div className="container">
          //     <Text
          //       tag="div"
          //       as="bodyH3"
          //       dangerouslySetInnerHTML={{
          //         __html: replaceHife(blogPage?.textSeo),
          //       }}
          //     />
          //   </div>
          // </section>
        )}

        <Breadcrumb
          data={[
            {
              title: blogPage.title,
              link: location.pathname,
              id: '' + location.pathname,
            },
          ]}
        />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query ($name: String!, $skip: Int!, $limit: Int!, $lang: String!) {
    umbracoBlogPage(name: { eq: $name }, lang: { eq: $lang }) {
      _url
      _urls {
        fr
        en_us
      }
      title
      name
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      imageCover {
        childImageSharp {
          id
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      imageCoverMobile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      videoCover {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      textSeo
      blockPush {
        name
        title
        backgroundColor
        title
        subtitle
        link {
          url
          type
          name
        }
        illustration {
          src
          gatsbyImageData(width: 400, quality: 90)
        }
      }
    }
    allUmbracoBlogPostCount: allUmbracoBlogPost(
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        _id
        shortTitle
        name
        _url
        _urls {
          fr
          en_us
        }
        chapeau
        _createDate(formatString: "MMMM Do, YYYY")
        introduction
        title
        articleBody
        category {
          name
          color
          title
        }
        thumbnail {
          childImageSharp {
            id
            gatsbyImageData(layout: CONSTRAINED, width: 1480)
          }
        }
        city {
          title
        }
      }
    }
    allUmbracoBlogPost(
      sort: { fields: [_createDate], order: DESC }
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        _id
        shortTitle
        name
        _url
        _urls {
          fr
          en_us
        }
        chapeau
        _createDate(formatString: "MMMM Do, YYYY")
        introduction
        title
        articleBody
        category {
          name
          color
          title
        }
        thumbnail {
          childImageSharp {
            id
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        city {
          title
        }
      }
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`
